var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"VA6sSVJ7A1vA7c4dhl3xH"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { init } from '@sentry/nextjs'

import { getSentryEnvironment } from '@/utils'

init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: getSentryEnvironment(process.env.NEXT_PUBLIC_ENV),

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: process.env.NODE_ENV === 'production' ? 0.5 : 0,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: Number(process.env.NEXT_PUBLIC_SENTRY_REPLAY_SAMPLE_RATE ?? 0.01),

  ignoreErrors: ['tolkie', '@npo'],
  beforeSend(event) {
    if (
      event.exception?.values?.[0]?.stacktrace?.frames?.some(
        (frame) => frame.filename?.includes('npoplayer.js') || frame.filename?.includes('ccm'),
      )
    ) {
      return null
    }
    return event
  },

  // Only the integrations listed here will be used
  integrations: [
    // replayIntegration({
    //   // Additional Replay configuration goes in here, for example:
    //   maskAllText: process.env.NODE_ENV !== 'production',
    //   blockAllMedia: process.env.NODE_ENV !== 'production',
    // }),
  ],
})
